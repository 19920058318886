<!--
 * @Author: your name
 * @Date: 2021-03-04 15:20:20
 * @LastEditTime: 2021-03-04 15:22:22
 * @LastEditors: Please set LastEditors
 * @Description: 网销推送
 * @FilePath: \sd-vue-admin\src\views\project\marketing\marketingPush\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
